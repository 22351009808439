<template>
  <div class="main-content section">
    <!-- Header -->
    <div class="header bg-gradient py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5 mb-5">
              <router-link :to="home">
                <img src="/assets/img/logo-NL-avec-SJ-blanc.png" alt=""/>
              </router-link>
            </div>
            <div class="align-content-end">
              <LangSelector @onLangChange="toLocale"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center mb-5">
        <div class="col-lg-4 col-md-12 p-0">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent">
              <div class="text-muted text-center mt-2 w-100">
                <h1>{{ $t('oubli-etape-2.title') }}</h1>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <form>
                <div class="form-group mb-3 pt-4 pt-lg-0">
                  <label for="password">{{ $t('oubli-etape-2.newPassword') }}</label>
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-lock"/></span>
                    </div>
                    <input
                        id="password"
                        v-model="password"
                        class="form-control"
                        name="pass"
                        type="password"
                    />
                  </div>
                </div>

                <div class="form-group mb-3 mt-4 pt-lg-0">
                  <label for="password_confirmation">{{
                      $t('oubli-etape-2.newPasswordConfirmation')
                    }}</label>
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-lock"/></span>
                    </div>
                    <input
                        id="password_confirmation"
                        v-model="passwordConfirmation"
                        class="form-control"
                        name="pass"
                        type="password"
                    />
                  </div>
                </div>
                <p class="m-0 pb-1">
                  <span :class="{'checkmark-expectations' : expectations.length}"><i class="fas fa-check"></i></span>
                  8 caractères
                </p>
                <p class="m-0 pb-1">
                  <span :class="{'checkmark-expectations' : expectations.uppercase}"><i class="fas fa-check"></i></span>
                  majuscule(s)
                </p>
                <p class="m-0 pb-1">
                  <span :class="{'checkmark-expectations' : expectations.numbers}"><i class="fas fa-check"></i></span>
                  chiffre(s)
                </p>
                <p class="m-0 pb-1">
                  <span :class="{'checkmark-expectations' : expectations.symbols} "><i class="fas fa-check"></i></span>
                  symbole(s)
                </p>
                <div class="text-center">
                  <button
                      class="btn btn-third mt-3 mb-2 mb-xl-4"
                      @click.prevent="doUpdatePasswordReset"
                  >
                    <i v-if="loader" class="fas fa-circle-notch fa-spin mr-1"/>
                    {{ $t('oubli-etape-2.changePasswordAction') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6"/>
            <div class="col-6 text-right">
              <router-link to="/login" class="text-light">
                <small>{{ $t('oubli.backToLogin') }}</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <Footer :fixed="!isMobile"/>
    </div>

    <div class="d-none d-xl-block">
      <img class="bg-zebre-forgotten-password" src="/assets/img/zebre-cle-qui-bouge-avec-herbes.svg"/>
    </div>
    <Toaster ref="toaster"/>
  </div>
</template>

<style>
.bg-zebre-forgotten-password {
  position: absolute;
  width: 60vh;
  bottom: 0;
  z-index: 0;
}
.checkmark-expectations {
  color: #5cb270;
}
</style>

<script>
import LangSelector from '@/views/Public/LangSelector.vue'
import Toaster from '@/components/UI/Toaster'
import PublicMixin from '@/views/Public/PublicMixin'
import {mapActions} from 'vuex'

export default {
  name: 'ForgottenPasswordStep2',
  title: 'register.forgotten-password',
  components: {
    LangSelector,
    Toaster
  },
  mixins: [PublicMixin],
  watch: {
    password: function (p) {
      this.expectations.length = (p.length > 8)
      this.expectations.numbers = (p.search(/[0-9]/) > 0)
      this.expectations.uppercase = (p.search(/[A-Z]/) > 0)

      const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
      this.expectations.symbols = isContainsSymbol.test(p)
    }
  },
  data: function () {
    return {
      message: {},
      loader: false,
      password: null,
      passwordConfirmation: null,
      redirectUrl: this.$route.query.redirect,
      expectations: {
        symbols: false,
        numbers: false,
        length: false,
        uppercase: false,
      }
    }
  },
  created() {
    this.$i18n.locale = this.$route.query.locale === 'fr' ? 'fr' : 'en'
  },
  methods: {
    ...mapActions('auth', ['updatePasswordReset']),
    doUpdatePasswordReset() {
      this.loader = true
      this.updatePasswordReset({
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        redirectUrl: this.redirectUrl
      })
          .then(() => {
            this.$store.dispatch('toast', {
              type: 'success',
              title: this.$t('oubli-etape-2.passwordChanged')
            })
            this.$router.push('/')
          })
          .catch(error => {
            //error.message = this.$t('oubli-etape-2.passwordChangedError')
            this.$store.dispatch('toastError', error)
          })
          .finally(() => {
            this.loader = false
          })
    }
  },
  mounted() {
  }
}
</script>
